// Packages Actions

import AvailabilityApiclient from '../../../api/clients/AvailabilityApiClient';
import ReservationsApiClient from '../../../api/clients/ReservationsApiClient';
import { DINING_CREDIT_PACKAGE_TITLE } from '../../../components/DiningCredit/utilities/copy';
import { editCopyByReplaceMultipleStrings } from '../../../components/utilities/copyFunctions';
import { isArrayEmpty } from '../../../utilities/arraysUtils';
import {
    HOWL_N_LEARN_CODE,
    LATE_CHECKOUT_PREFIXES,
    PACKAGE_CHARGE_TYPE,
    PACKAGE_TYPE
} from '../../../utilities/constants';
import { getKidsUnderAge } from '../../../utilities/guestsUtils';
import { getResortLocation } from '../CurrentLodge/currentLodge.selectors';
import packagesActionTypes from './packages.types';

const getAdjustmentPackage = selectedPackage => {
  if (selectedPackage.adjustmentCode && selectedPackage.adjustmentCode.length > 0) {
    return {
      packageCode: selectedPackage.adjustmentCode[0],
      quantity: selectedPackage.quantity,
      isAdjustment: true,
      parentPackageCode: selectedPackage.packageCode,
      packageType: selectedPackage.packageType
    };
  }
  return null;
};

function upsertToPackagesArray(packagesArray, obj) {
  let result = [...packagesArray];
  const index = result.findIndex(e => matchByPackageCode(e, obj));
  if (index === -1) {
    result.push(obj);
  } else {
    result[index] = obj;
  }
  return result;
}

function removeFromPackagesArray(packagesArray, obj) {
  return packagesArray.filter(function(e) {
    return e.packageCode !== obj.packageCode;
  });
}

const matchByPackageCode = (e, params) => e.packageCode === params.packageCode;

export const getPackageAvailability = params => dispatch => {
  dispatch({ type: packagesActionTypes.GET_PACKAGES_AVAILABILITY_INFO_LOADING, isLoading: true });

  let apiClient = new AvailabilityApiclient('v2.2');
  const request = apiClient.getAvailabilityPackage(params);

  return request.then(
    response => {
      dispatch({
        type: packagesActionTypes.GET_PACKAGES_AVAILABILITY_INFO_SUCCESS,
        packageAvailabilityList: response.data.packages,
        isLoading: false
      });

      dispatch({
        type: packagesActionTypes.SET_PACKAGES_PLAN_INFO_SUCCESS,
        planPackages: response.data.packageTable,
        isLoading: false
      });
    },
    err => {
      dispatch({
        type: packagesActionTypes.GET_PACKAGES_AVAILABILITY_INFO_ERROR,
        packageAvailabilityList: [],
        isLoading: false,
        requestFailed: true,
        errorMessage: `Oops, ${err.message}`
      });
    }
  );
};
/**
 * when non billable kid is added, check if selected package contains adjustment code, if available add the adjustment package to selected package list.
 */

export const upsertPackage = params => (dispatch, getState) => {
  const state = getState();
  const resortLocation = getResortLocation(state.currentLodge);
  let currentPackages = state.packages.selectedPackages;
  const kidsAgesList = state.guests.kidsAges;
  const nonBillableChildren = getKidsUnderAge(kidsAgesList, 4);

  let selectedPackages = upsertToPackagesArray(currentPackages, params);
  if (nonBillableChildren > 0) {
    const adjustmentPackage = getAdjustmentPackage(params);
    if (adjustmentPackage) {
      selectedPackages = upsertToPackagesArray(selectedPackages, adjustmentPackage);
    }
  }
  const learnPackage = selectedPackages.find(pckg => pckg.packageCode === HOWL_N_LEARN_CODE);
  if (learnPackage) {
    const lcoPackage = selectedPackages.find(selectedPackages =>
      selectedPackages.packageCode.includes(LATE_CHECKOUT_PREFIXES.code)
    );
    if (lcoPackage) {
      selectedPackages = removeFromPackagesArray(selectedPackages, lcoPackage);
    }
  }
  dispatch({
    type: packagesActionTypes.UPSERT_PACKAGES,
    selectedPackages: selectedPackages,
    selectedPkgsLocationCode: resortLocation
  });
};
export const lateCheckoutOffered = value => dispatch => {
  dispatch({
    type: packagesActionTypes.OFFERED_PACKAGES_LCO,
    isLateCheckoutOffered: value
  });
};
export const lateCheckoutDecline = value => dispatch => {
  dispatch({
    type: packagesActionTypes.DECLINE_PACKAGES_LCO,
    isLateCheckoutDenied: value
  });
};
export const deletePackage = params => (dispatch, getState) => {
  let currentPackages = [...getState().packages.selectedPackages];
  const adjustmentPackages = currentPackages.filter(w => w.parentPackageCode === params.packageCode);
  if (adjustmentPackages.length > 0) {
    currentPackages = removeFromPackagesArray(currentPackages, adjustmentPackages[0]);
  }
  const remainingPackages = removeFromPackagesArray(currentPackages, params);
  dispatch({
    type: packagesActionTypes.DELETE_PACKAGES,
    selectedPackages: remainingPackages
  });
};
export const clearSelectedPackages = () => dispatch => {
  dispatch({
    type: packagesActionTypes.CLEAR_PACKAGES_SELECTED,
    selectedPackages: [],
    selectedPkgsLocationCode: ''
  });
};
export const clearPackagesByCodes = packagesCodes => (dispatch, getState) => {
  const currentPackages = getState().packages.selectedPackages;
  const selectedPackages = currentPackages.filter(
    selectedPackage => !packagesCodes.includes(selectedPackage.packageCode)
  );
  dispatch({
    type: packagesActionTypes.CLEAR_PACKAGES_BY_CODES,
    selectedPackages: selectedPackages
  });
};
export const clearPlanPackages = () => dispatch => {
  dispatch({
    type: packagesActionTypes.CLEAR_PACKAGES_PLAN,
    planPackages: [],
    selectedPackages: [],
    selectedPkgsLocationCode: ''
  });
};
export const getCabanas = params => async dispatch => {
  dispatch({ type: packagesActionTypes.GET_PACKAGES_CABANAS_AVAILABILITY_LOADING, isLoading: true });
  // V3
  const apiClient = new ReservationsApiClient('v2.9');
  const request = apiClient.fetchCabanasV3(params);

  return request.then(
    response => {
      dispatch({
        type: packagesActionTypes.GET_PACKAGES_CABANAS_AVAILABILITY_SUCCESS,
        cabanasAvailabilityList: response.data,
        isLoading: false
      });
    },
    err => {
      dispatch({
        type: packagesActionTypes.GET_PACKAGES_CABANAS_AVAILABILITY_ERROR,
        cabanasAvailabilityList: [],
        isLoading: false,
        requestFailed: true,
        errorMessage: `Oops, ${err.message}`
      });
    }
  );
};
export const packagesImpressions = eventProps => dispatch => {
  dispatch({
    type: packagesActionTypes.GTM_PACKAGES_IMPRESSIONS,
    eventProps
  });
};
export const updateCurrentPackagesOnSuiteChange = () => (dispatch, getState) => {
  const state = getState();

  // DINING CREDIT ACTIVE
  const diningCreditActive = state.lodgeConfig.isCreditPackageActive;

  // GET PACKAGES AND DATA FROM STATE
  let currentPackages = [...state.packages.selectedPackages];

  // BREAK IF THERE ARE NO SELECTED PACKAGES
  if (isArrayEmpty(currentPackages)) {
    return;
  }
  const availablePackages = [...state.packages.planPackages];
  const kidsAgesList = [...state.guests.kidsAges];
  const nonBillableChildren = getKidsUnderAge(kidsAgesList, 4);

  // REMOVE ALL LCO PACKAGES
  const lcoPackage = currentPackages.find(selectedPackages =>
    selectedPackages.packageCode.includes(LATE_CHECKOUT_PREFIXES.code)
  );
  if (lcoPackage) {
    currentPackages = removeFromPackagesArray(currentPackages, lcoPackage);
  }

  // REMOVE BUNDLE PACKAGES
  const bundlePackage = currentPackages.find(selectedPackages =>
    selectedPackages.packageType.includes(PACKAGE_TYPE.bundle)
  );
  if (bundlePackage) {
    currentPackages = removeFromPackagesArray(currentPackages, bundlePackage);
  }

  // REMOVE ALL ADJUSTMENT PACKAGES
  const adjPackages = currentPackages.filter(selectedPackages => selectedPackages.packageCode.includes('ADJ'));
  if (adjPackages) {
    adjPackages.forEach(adjPackage => {
      currentPackages = removeFromPackagesArray(currentPackages, adjPackage);
    });
  }

  // UPDATE PACKAGES WITH NEW DATA
  let selectedPackages = [];
  currentPackages.forEach(currentPackage => {
    // GET PKG QTY AND CODE
    const currentPkgQty = currentPackage.quantity;
    const currentPkgCode = currentPackage.packageCode;

    // TEMP DATA
    let availablePkgTempData = null;

    // FIND CURENT PKG ON AVAILABLE PKGS
    availablePackages.forEach(availablePackage => {
      if (!isArrayEmpty(availablePackage.packageList)) {
        const pkgData = availablePackage.packageList.find(pkg => pkg.packageCode === currentPkgCode);
        if (pkgData) {
          availablePkgTempData = pkgData;
        }
      }
    });

    // PACKAGE FOUND
    if (availablePkgTempData) {
      // FORMAT TEMP DATA
      let packageTitle =
        availablePkgTempData.packageTitle || availablePkgTempData.shortDescription || availablePkgTempData.title;

      // FORMAT PACKAGE NAME IF DINING CREDIT
      if (
        diningCreditActive &&
        availablePkgTempData.chargeType === PACKAGE_CHARGE_TYPE.byPackage &&
        availablePkgTempData.packageType.includes(PACKAGE_TYPE.dining)
      ) {
        const copyObject = {
          '<ALLOWANCE>': availablePkgTempData.allowance,
          '<AMOUNT>': availablePkgTempData.amount
        };
        packageTitle = editCopyByReplaceMultipleStrings(DINING_CREDIT_PACKAGE_TITLE, copyObject);
      }

      const packageData = {
        packageName: packageTitle,
        quantity: currentPkgQty,
        ...availablePkgTempData
      };

      selectedPackages.push(packageData);
      if (nonBillableChildren > 0) {
        const adjustmentPackage = getAdjustmentPackage(packageData);
        if (adjustmentPackage) {
          selectedPackages.push(adjustmentPackage);
        }
      }
    }
  });

  dispatch({
    type: packagesActionTypes.UPSERT_PACKAGES,
    selectedPackages: selectedPackages,
    selectedPkgsLocationCode: getResortLocation(state.currentLodge)
  });
};
