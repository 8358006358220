import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
    TESTS_DICTIONARY,
    getTestValueAB,
    optimizelyHideVoyagersBannerMobile
} from '../../../../../../utilities/ABTesting';

import Loyalty from '../../../../../Loyalty/Loyalty';
import { LOYALTY_TYPES } from '../../../../../Loyalty/utilities/constants';
import theme from '../../../../../ui-kit/themes/theme';
import { handlePointsBalance } from '../../../../../utilities/utils';
import { SummaryContent } from '../../../Content';
import * as COPY from '../../utilities/copy';
import { AdditionalCharges } from './AdditionalCharges';
import { Details } from './Details';
import { DueAmount } from './DueAmount';
import { Header as SuiteHeader } from './Header';
import Offers from './Offers';
import { PackagesList } from './PackageList';
import { ParkingFee } from './ParkingFee';
import Points from './Points';
import { Subtotal } from './Subtotal';
import { TotalSavings } from './TotalSavings';
import { Totals } from './Totals';

const SuitePlanContent = ({ expanded, onPaymentPage, clearSelectedSuite, ...restProps }) => {
  const {
    promotionalSavings,
    loyaltyOptIn,
    suiteTotal,
    numberOfNights,
    userOffers,
    activeOffers,
    removeActiveOffer,
    showLoyalty,
    isUserAuthenticated,
    getPointsMultiplier,
    offerCode,
    pointsBalance,
    isCartVariation,
    resortLocation,
    loyaltyEarnThresholdNights,
    setCartReturnedPageLinkClicked,
    clearPlanPackages,
    deletePackage,
    upsertPackage
  } = restProps;

  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg})`
  });
  const iconSize = isDesktop ? '16px' : '24px';

  const [showBonusExcedent, setShowBonusExcedent] = useState(false);

  const renderHeader = expanded || onPaymentPage || isDesktop;
  const renderTaxes = expanded && onPaymentPage;
  const renderExpandedNonPaymentPage = expanded && !onPaymentPage;
  const renderPackages = expanded;
  const renderTotal = ((expanded || isDesktop) && onPaymentPage && isDesktop) || (!isDesktop && onPaymentPage);
  const abTestingHideEarnBanner = !isDesktop && optimizelyHideVoyagersBannerMobile;

  const isSuperscriptStaySummarySuite = getTestValueAB(TESTS_DICTIONARY.optimizelySuperscriptStaySummarySuite, false);
  const optimizelyHierarchyFlip = getTestValueAB(TESTS_DICTIONARY.optimizelyHierarchyFlip, false);

  const handleSavings = () => {
    const roomSavings = ~~parseFloat(promotionalSavings.toFixed(2));
    const pointsSavings = handlePointsBalance(showLoyalty, loyaltyOptIn, pointsBalance, suiteTotal);
    let offersSavings = 0;
    if (userOffers && userOffers.length > 0) {
      offersSavings = userOffers.reduce((previousValue, currentValue) => {
        if (activeOffers.length > 0 && activeOffers.includes(currentValue.id)) {
          return previousValue + currentValue.dollarValue;
        } else {
          return previousValue;
        }
      }, 0);
    }
    return Number(roomSavings) + Number(pointsSavings) + offersSavings;
  };

  return (
    <SummaryContent fontSize={[14, null, null, 0]}>
      {showLoyalty && !abTestingHideEarnBanner && offerCode !== COPY.SEASONS_CODE && !isCartVariation ? (
        <Loyalty
          loyaltyType={LOYALTY_TYPES.earn}
          numberOfNights={numberOfNights}
          isUserAuthenticated={isUserAuthenticated}
          suitNightRate={restProps.suiteTotal}
          pointsMultiplier={getPointsMultiplier}
          loyaltyEarnThresholdNights={loyaltyEarnThresholdNights}
          {...restProps}
        />
      ) : null}

      {renderHeader && !isCartVariation ? <SuiteHeader {...restProps} /> : null}
      {expanded ? (
        <Details
          {...restProps}
          setCartReturnedPageLinkClicked={setCartReturnedPageLinkClicked}
          clearPlanPackages={clearPlanPackages}
          clearSelectedSuite={clearSelectedSuite}
          handleSavings={handleSavings}
          iconSize={iconSize}
        />
      ) : null}
      {renderPackages ? (
        <PackagesList
          {...restProps}
          setCartReturnedPageLinkClicked={setCartReturnedPageLinkClicked}
          deletePackage={deletePackage}
          upsertPackage={upsertPackage}
        />
      ) : null}
      {showLoyalty && expanded && userOffers ? (
        <Offers
          isCartVariation={isCartVariation}
          userOffers={userOffers}
          activeOffers={activeOffers}
          removeOffer={removeActiveOffer}
          excedent={showBonusExcedent}
        />
      ) : null}
      {showLoyalty && expanded && pointsBalance ? <Points {...restProps} isCartVariation={isCartVariation} /> : null}

      {renderTaxes && !isCartVariation ? <AdditionalCharges {...restProps} isDesktop={isDesktop} /> : null}
      {expanded && !isCartVariation ? (
        <TotalSavings optimizelyHierarchyFlip={optimizelyHierarchyFlip} handleSavings={handleSavings} {...restProps} />
      ) : null}
      {renderExpandedNonPaymentPage && !isCartVariation ? (
        <Subtotal
          isSuperscriptStaySummarySuite={isSuperscriptStaySummarySuite}
          optimizelyHierarchyFlip={optimizelyHierarchyFlip}
          {...restProps}
          setExcedent={setShowBonusExcedent}
          resortLocation={resortLocation}
        />
      ) : null}

      {renderTotal && !optimizelyHierarchyFlip && !isCartVariation ? (
        <Totals
          isSuperscriptStaySummarySuite={isSuperscriptStaySummarySuite}
          {...restProps}
          setExcedent={setShowBonusExcedent}
          resortLocation={resortLocation}
        />
      ) : null}
      {renderTotal && !isCartVariation ? (
        <DueAmount optimizelyHierarchyFlip={optimizelyHierarchyFlip} {...restProps} />
      ) : null}
      {renderTotal && !renderExpandedNonPaymentPage && !isCartVariation ? <ParkingFee /> : null}
      {renderTotal && optimizelyHierarchyFlip && !isCartVariation ? (
        <Totals
          optimizelyHierarchyFlip={optimizelyHierarchyFlip}
          isDesktop={isDesktop}
          isSuperscriptStaySummarySuite={isSuperscriptStaySummarySuite}
          {...restProps}
          setExcedent={setShowBonusExcedent}
          resortLocation={resortLocation}
        />
      ) : null}
      {expanded && isCartVariation && handleSavings() ? (
        <TotalSavings handleSavings={handleSavings} {...restProps} isCartVariation={isCartVariation} />
      ) : null}
    </SummaryContent>
  );
};

export default SuitePlanContent;
