// Plans Reducer

import { SORTING_CRITERIA, SUITE_TYPES } from '../../../utilities/constants';
import { initializeState } from '../../../utilities/storageUtils';
import plansActionTypes from './plans.types';

const INITIAL_STATE = {
  availabilityList: [],
  qualifyingID: undefined,
  requiresQualifyingID: false,
  isIdMeValidationRequired: false,
  qualifyingIDError: false,
  offerCodeError: undefined,
  promoDescription: '',
  availabilityReqIsLoading: false,
  availabilityRequestFailed: false,
  availabilityErrorMessage: '',
  status: '',
  selectedSuiteForRange: '',
  selectedSuiteRange: {},
  selectedSuiteRangeRestrictions: {},
  isOpenRatesCalendar: false,
  recentSearchesList: [],
  searchesGetReqIsLoading: false,
  searchesSetReqIsLoading: false,
  searchesDelReqIsLoading: false,
  searchesReqFailed: false,
  searchesErrorMessage: '',
  selectedSuiteForCalendar: '',
  availabilityHighestRatesList: [],
  availabilityHighestRatesReqIsLoading: false,
  availabilityHighestRatesRequestFailed: false,
  filtersApplied: {
    amenities: [],
    accessibility: false,
    themed: false,
    standard: false,
    premium: false,
    condo: false,
    cottage: false
  },
  sorting: SORTING_CRITERIA.recommendedForYou,
  sortSuite: '',
  oldFiltersApplied: {},
  suiteAvailabilityList: [],
  getAvailabilityRangeIsLoading: false,
  getAvailabilityRangeFailed: false,
  getAvailabilityRangeMessage: '',
  getAvRangeBySuiteIsLoading: false,
  getAvRangeBySuiteFailed: false,
  getAvRangeBySuiteErrorMsg: '',
  isAPScall: false,
  itemQuantity: [],
  itemQuantityReqIsLoading: false,
  itemQuantityReqFailed: false,
  emailCampaign: {
    isEmailCampaign: false,
    planCalendarShouldBeBlocked: false,
    startCalendarAt: '',
    endCalendarAt: '',
    campaignObject: {}
  },
  emailCampaignSuiteType: SUITE_TYPES.unknown,
  isBookingWidgetFocused: false,
  isErrorModalAlreadyClosed: false,
  offerType: '',
  lcoPackages: {},
  personalizationResponses: [],
  dealsSummary: {},
  leavePayment: false,
  lowestRateCalendarList: {},
  lowestRatesByStayList: {},
  lowestRatesByStayListTotal: {},
  lastCalendarRatesDateSelection: { start: '', end: '' },
  getLowestRateCalendarErrorMsg: '',
  upsellPackagesContent: [],
  isCaliforniaResident: false,
  isTwoRateTypesSuites: false,
  latestVersionJEventErrorObj: null,
  latestVersionHEventErrorObj: null,
  isCartOpenClose: false,
  cartReturnedPageLinkClicked: ''
};

const plansReducer = (state, action) => {
  state = initializeState(state, INITIAL_STATE);

  // Get Book information reducers
  switch (action.type) {
    case plansActionTypes.SET_PLANS_AVAILABILITY_DETAILS_ERROR:
      return {
        ...state,
        offerCodeError: action.error,
        availabilityRequestFailed: false,
        availabilityReqIsLoading: false,
        availabilityErrorMessage: ''
      };
    case plansActionTypes.SET_PLANS_IS_CART_OPEN_CLOSE:
      return {
        ...state,
        isCartOpenClose: action.value
      };
    case plansActionTypes.SET_PLANS_IS_CART_OPEN_CLOSE_CLEAN:
      return {
        ...state,
        isCartOpenClose: false
      };
    case plansActionTypes.SET_PLANS_CART_RETURNED_PAGE_LINK_CLICKED:
      return {
        ...state,
        cartReturnedPageLinkClicked: action.pageToReturn
      };
    case plansActionTypes.SET_PLANS_CART_RETURNED_PAGE_LINK_CLICKED_CLEAN:
      return {
        ...state,
        cartReturnedPageLinkClicked: ''
      };
    // Availability item request succeded
    case plansActionTypes.GET_PLANS_AVAILABILITY_INFO_SUCCESS:
      return {
        ...state,
        availabilityList: action.availabilityList,
        isAPScall: action.isAPScall,
        availabilityRequestFailed: false,
        availabilityReqIsLoading: false,
        availabilityErrorMessage: '',
        requiresQualifyingID: action.requiresQualifyingID,
        isIdMeValidationRequired: action.isIdMeValidationRequired,
        offerCodeError: action.offerCodeError,
        isErrorModalAlreadyClosed: action.isErrorModalAlreadyClosed,
        promoDescription: action.promoDescription,
        offerType: action.offerType,
        personalizationRues: action.personalizationRules,
        personalizationResponses: action.personalizationResponses,
        lcoPackages: action.lcoPackages,
        upsellPackagesContent: action.upsellPackagesContent,
        isTwoRateTypesSuites: action.isTwoRateTypesSuites
      };
    // The request is currently running
    case plansActionTypes.GET_PLANS_AVAILABILITY_INFO_LOADING:
      return {
        ...state,
        availabilityReqIsLoading: action.availabilityReqIsLoading,
        ...(action.availabilityReqIsLoading && {
          availabilityList: []
        }),
        ...(!action.shouldIgnoreOfferCodeError && {
          offerCodeError: action.offerCodeError
        })
      };
    // Availability item request failed
    case plansActionTypes.GET_PLANS_AVAILABILITY_INFO_ERROR:
      return {
        ...state,
        availabilityList: action.availabilityList,
        availabilityRequestFailed: action.availabilityRequestFailed,
        availabilityErrorMessage: action.availabilityErrorMessage,
        availabilityReqIsLoading: false,
        requiresQualifyingID: action.requiresQualifyingID,
        isIdMeValidationRequired: action.isIdMeValidationRequired,
        promoDescription: action.promoDescription
      };
    case plansActionTypes.SET_PLANS_IS_ERROR_MODAL_ALREADY_CLOSED:
      return {
        ...state,
        isErrorModalAlreadyClosed: action.value
      };
    // Recent Searches items request succeded
    case plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_SUCCESS:
      return {
        ...state,
        recentSearchesList: action.recentSearchesList,
        searchesReqFailed: false,
        searchesErrorMessage: ''
      };
    // The request is currently running
    case plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_LOADING:
      return {
        ...state,
        searchesGetReqIsLoading: action.searchesGetReqIsLoading
      };
    // The set request is currently running
    case plansActionTypes.SET_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS:
      return {
        ...state,
        searchesSetReqIsLoading: action.searchesSetReqIsLoading
      };
    // The delete request is currently running
    case plansActionTypes.DEL_PLANS_REQUEST_RECENT_SEARCHES_INFO_SUCCESS:
      return {
        ...state,
        searchesDelReqIsLoading: action.searchesDelReqIsLoading
      };
    // Recent Searches item request failed
    case plansActionTypes.GET_PLANS_RECENT_SEARCHES_INFO_ERROR:
      return {
        ...state,
        searchesReqFailed: action.searchesReqFailed,
        searchesErrorMessage: action.searchesErrorMessage
      };

    case plansActionTypes.SELECT_PLANS_SUITE_FOR_RANGE_TYPE:
      return {
        ...state,
        selectedSuiteForRange: action.selectedSuiteForRange
      };

    case plansActionTypes.UNSELECT_PLANS_SUITE_FOR_RANGE_TYPE:
      return {
        ...state,
        selectedSuiteForRange: INITIAL_STATE.selectedSuiteForRange
      };

    case plansActionTypes.OPEN_PLANS_RATES_CALENDAR:
      return {
        ...state,
        selectedSuiteRange: action.selectedSuiteRange,
        dealsSummary: action.dealsSummary
      };

    case plansActionTypes.CLOSE_PLANS_RATES_CALENDAR:
      return {
        ...state,
        isOpenRatesCalendar: false,
        selectedSuiteRange: INITIAL_STATE.selectedSuiteRange
      };

    case plansActionTypes.OPEN_PLANS_RATES_CALENDAR_RESTRICTION:
      return {
        ...state,
        selectedSuiteRangeRestrictions: action.selectedSuiteRangeRestrictions,
        dealsSummary: action.dealsSummary
      };

    case plansActionTypes.OPEN_PLANS_CALENDAR_RATES_BY_STAY:
      return {
        ...state,
        lowestRatesByStayList: action.lowestRatesByStayList,
        lowestRatesByStayListTotal: action.lowestRatesByStayListTotal
      };

    case plansActionTypes.LAST_PLANS_CALENDAR_RATES_BY_STAY_SELECTION:
      return {
        ...state,
        lastCalendarRatesDateSelection: action.lastCalendarRatesDateSelection
      };

    case plansActionTypes.CLEAN_PLANS_CALENDAR_RATES_BY_STAY:
      return {
        ...state,
        lowestRatesByStayList: {},
        lowestRatesByStayListTotal: {}
      };

    case plansActionTypes.OPEN_PLANS_RATES_CALENDAR_RESTRICTION_INCREMENT:
      return {
        ...state,
        selectedSuiteRangeRestrictions:
          Object.keys(action.selectedSuiteRangeRestrictions).length > 0
            ? { ...state.selectedSuiteRangeRestrictions, ...action.selectedSuiteRangeRestrictions }
            : { ...state.selectedSuiteRangeRestrictions },
        dealsSummary: action.dealsSummary
      };

    case plansActionTypes.GET_PLANS_LOWEST_RATES_CALENDAR:
      return {
        ...state,
        lowestRateCalendarList: action.lowestRateCalendarList
      };

    case plansActionTypes.GET_PLANS_LOWEST_RATES_CALENDAR_MOBILE:
      return {
        ...state,
        lowestRateCalendarList:
          Object.keys(action.lowestRateCalendarList).length > 0
            ? { ...state.lowestRateCalendarList, ...action.lowestRateCalendarList }
            : { ...state.lowestRateCalendarList }
      };

    case plansActionTypes.GET_PLANS_LOWEST_RATES_CALENDAR_ERROR:
      return {
        ...state,
        getLowestRateCalendarErrorMsg: action.getLowestRateCalendarErrorMsg
      };

    case plansActionTypes.CLEAN_PLANS_CALENDAR_RESTRICTIONS_AND_LOWEST_RATES:
      return {
        ...state,
        lowestRateCalendarList: INITIAL_STATE.lowestRateCalendarList,
        selectedSuiteRangeRestrictions: INITIAL_STATE.selectedSuiteRangeRestrictions
      };

    case plansActionTypes.CLOSE_PLANS_RATES_CALENDAR_RESTRICTION:
      return {
        ...state,
        isOpenRatesCalendar: false,
        selectedSuiteRangeRestrictions: INITIAL_STATE.selectedSuiteRangeRestrictions
      };

    case plansActionTypes.OPEN_PLANS_PAYMENT_LEAVE_MODAL:
      return {
        ...state,
        leavePayment: true
      };

    case plansActionTypes.CLOSE_PLANS_PAYMENT_LEAVE_MODAL:
      return {
        ...state,
        leavePayment: false
      };

    case plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_LOADING:
      return {
        ...state,
        getAvRangeBySuiteIsLoading: action.getAvRangeBySuiteIsLoading
      };
    case plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_ERROR:
      return {
        ...state,
        getAvRangeBySuiteFailed: action.getAvRangeBySuiteFailed,
        getAvRangeBySuiteErrorMsg: action.getAvRangeBySuiteErrorMsg
      };
    case plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_BY_SUITE_RESET:
      return {
        ...state,
        selectedSuiteRange: {},
        getAvRangeBySuiteIsLoading: false,
        getAvRangeBySuiteFailed: false,
        getAvRangeBySuiteErrorMsg: ''
      };
    case plansActionTypes.GET_PLANS_AVAILABILITY_HIGHEST_RATES_SUCCESS:
      return {
        ...state,
        availabilityHighestRatesList: action.availabilityHighestRatesList,
        availabilityHighestRatesIsLoading: false,
        availabilityHighestRatesRequestFailed: false
      };
    case plansActionTypes.GET_PLANS_AVAILABILITY_HIGHEST_RATES_ERROR:
      return {
        ...state,
        availabilityHighestRatesList: [],
        availabilityHighestRatesIsLoading: false,
        availabilityHighestRatesRequestFailed: true
      };
    case plansActionTypes.SET_PLANS_APPLIED_FILTERS:
      return {
        ...state,
        filtersApplied: action.filtersApplied,
        oldFiltersApplied: state.filtersApplied
      };

    case plansActionTypes.SET_PLANS_SELECTED_SUITE_NAME_FOR_CALENDAR:
      return {
        ...state,
        selectedSuiteForCalendar: action.selectedSuiteForCalendar
      };

    case plansActionTypes.GET_PLANS_AVAILABILITY_RANGE:
      return {
        ...state,
        getAvailabilityRangeIsLoading: action.getAvailabilityRangeIsLoading
      };
    case plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_SUCCESS:
      return {
        ...state,
        suiteAvailabilityList: action.suiteAvailabilityList,
        getAvailabilityRangeIsLoading: false,
        getAvailabilityRangeFailed: false,
        getAvailabilityRangeMessage: ''
      };
    case plansActionTypes.GET_PLANS_AVAILABILITY_RANGE_ERROR:
      return {
        ...state,
        suiteAvailabilityList: [],
        getAvailabilityRangeIsLoading: false,
        getAvailabilityRangeFailed: true,
        getAvailabilityRangeMessage: action.getAvailabilityRangeMessage
      };
    case plansActionTypes.GET_PLANS_ITEM_QUANTITY_TYPE:
      return {
        ...state,
        itemQuantityReqIsLoading: action.itemQuantityReqIsLoading
      };
    case plansActionTypes.GET_PLANS_ITEM_QUANTITY_SUCCESS:
      return {
        ...state,
        itemQuantity: action.itemQuantity,
        itemQuantityReqIsLoading: false,
        itemQuantityReqFailed: false
      };
    case plansActionTypes.GET_PLANS_ITEM_QUANTITY_ERROR:
      return {
        ...state,
        itemQuantity: action.itemQuantity,
        itemQuantityReqIsLoading: false,
        itemQuantityReqFailed: true
      };
    case plansActionTypes.TOGGLE_PLANS_EMAIL_CAMPAIGNS:
      return {
        ...state,
        emailCampaign: action.emailCampaign
      };
    case plansActionTypes.SET_PLANS_QUALIFYING_ID:
      return {
        ...state,
        qualifyingID: action.code
      };
    case plansActionTypes.GET_PLANS_QUALIFYING_ID_HAS_ERROR:
      return {
        ...state,
        qualifyingIDError: action.hasError
      };
    case plansActionTypes.SET_PLANS_TAB_INDEX_BY_EMAIL_CAMPAIGN:
      return {
        ...state,
        emailCampaignSuiteType: action.emailCampaignSuiteType
      };
    case plansActionTypes.SET_PLANS_IS_BOOKING_WIDGET_FOCUSED:
      return {
        ...state,
        isBookingWidgetFocused: action.value
      };
    case plansActionTypes.SET_PLANS_SORTING_ORDER:
      return {
        ...state,
        sorting: action.sortingType
      };
    case plansActionTypes.SET_PLANS_SUITE_CODE_FILTER:
      return {
        ...state,
        sortSuite: action.suiteCode
      };
    case plansActionTypes.SET_PLANS_IS_CALIFORNIA_RESIDENT:
      return {
        ...state,
        isCaliforniaResident: action.isCaliforniaResident
      };
    case plansActionTypes.CLEAN_PLANS_IS_CALIFORNIA_RESIDENT:
      return {
        ...state,
        isCaliforniaResident: INITIAL_STATE.isCaliforniaResident
      };
    case plansActionTypes.SET_PLANS_LATEST_J_VERSION_EVENT_ERROR:
      return {
        ...state,
        latestVersionJEventErrorObj: action.variationJObj
      };
    case plansActionTypes.SET_PLANS_LATEST_H_VERSION_EVENT_ERROR:
      return {
        ...state,
        latestVersionHEventErrorObj: action.variationHObj
      };
    case plansActionTypes.CLEAR_PLANS_ALL_AVAILABILITY_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default plansReducer;
