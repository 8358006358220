import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import Scrollable from '../../ui-kit/Scrollable';
import theme from '../../ui-kit/themes/theme';
import useCorrectedHeight from '../../utilities/useCorrectedHeight';

import { isInIframe } from '../../../utilities/customEventsHelpers';
import ModalHeader from './ModalHeader';
import { CustomBlockUi, ModalContainer, OutsideContainer } from './styles';

const DrawerModal = props => {
  const {
    children,
    isOpen,
    closeButton,
    onRequestClose,
    backButton,
    backButtonSize,
    backButtonVertPosValue,
    onBackButtonClick,
    title,
    isBlocking,
    width,
    fontSize,
    lineHeight,
    className,
    innerPadding,
    titleColor,
    closeBtnPosition,
    closeBtnSize,
    closeBtnVertPosValue,
    closeBtnHorizonPosValue,
    closeBtnColor,
    borderRadius,
    hasTopPadding,
    headerBackgroundColor,
    titleWidth,
    containerBorderRadius,
    ...otherProps
  } = props;

  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  const maxHeight = useCorrectedHeight(98);

  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      isOpen={isOpen}
      preventScroll
      onRequestClose={onRequestClose}
      closeTimeoutMS={250}>
      <Scrollable maxHeight={maxHeight} hideScrollWhileScrolling>
        <OutsideContainer flexDirection="column">
          <ModalHeader
            topPadding={closeButton || title}
            closeBtnPosition={closeBtnPosition}
            closeBtnSize={closeBtnSize}
            closeBtnVertPosValue={closeBtnVertPosValue}
            closeBtnHorizonPosValue={closeBtnHorizonPosValue}
            closeBtnColor={closeBtnColor}
            backButton={backButton}
            backButtonSize={backButtonSize}
            backButtonVertPosValue={backButtonVertPosValue}
            onBackButtonClick={onBackButtonClick}
            title={title}
            fontSize={fontSize}
            lineHeight={lineHeight}
            closeButton={closeButton}
            onCloseModal={onRequestClose}
            titleColor={titleColor}
            headerBackgroundColor={headerBackgroundColor}
            width={titleWidth}
          />
          <CustomBlockUi tag="div" blocking={isBlocking} containerBorderRadius={containerBorderRadius}>
            <ModalContainer hasTopPadding={hasTopPadding} innerPadding={innerPadding}>
              {React.cloneElement(children, otherProps)}
            </ModalContainer>
          </CustomBlockUi>
        </OutsideContainer>
      </Scrollable>
    </Modal>
  );
};

const StyledDrawerModal = styled(DrawerModal).attrs({
  className: 'DrawerModal'
})`
  .DrawerModal__overlay {
    z-index: ${({ zIndex }) => {
      if (zIndex) return zIndex;

      return theme.zIndex.auth;
    }};
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${isInIframe ? '' : 'background-color: rgba(57, 84, 118, 0.35);'};
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    z-index: ${theme.zIndex.modal};
    transition: all 0.25s ease-in-out;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .DrawerModal__content {
    border-top-right-radius: ${theme.radii[5]}px;
    border-top-left-radius: ${theme.radii[5]}px;
    ${({ borderRadius }) =>
      'border-top-right-radius:' + borderRadius + '; border-top-left-radius:' + borderRadius + ';'};
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: ${({ backGroundColor }) => (backGroundColor ? backGroundColor : theme.colors.pureWhite[0])};
    max-height: 100%;
    overflow: hidden;
    padding: 0;
    transform: translateY(100vh);
    transition: transform 0.25s ease-in-out;
    width: 100%;

    ${theme.mediaQueries.md} {
      > div {
        padding-right: 0px !important;
      }
    }
  }

  .ReactModal__Content--after-open {
    transform: translateY(0);
  }

  .ReactModal__Content--before-close {
    transform: translateY(100vh);
  }

  ${theme.mediaQueries.md} {
    .DrawerModal__overlay {
      align-items: center;
    }

    .DrawerModal__content {
      ${({ borderRadius }) => 'border-radius:' + borderRadius};
      transform: scale(0.8);
      transition: transform 0.25s ease-in-out;
      width: ${({ width }) => `${width}px`};
    }

    .ReactModal__Content--after-open {
      transform: scale(1);
    }

    .ReactModal__Content--before-close {
      transform: scale(0.8);
    }
  }
`;

StyledDrawerModal.defaultProps = {
  closeBtnPosition: 'right',
  closeBtnSize: 16,
  closeBtnVertPosValue: '32px',
  closeBtnHorizonPosValue: '0px',
  closeBtnColor: 'inherit',
  borderRadius: `${theme.radii[5]}px`,
  hasTopPadding: true,
  children: null,
  isVisible: false,
  onCloseComplete: null,
  backButton: false,
  onBackButtonClick: null,
  title: '',
  fontSize: null,
  lineHeight: null,
  isBlocking: false,
  width: 480,
  closeButton: true,
  innerPadding: theme.space[4]
};

StyledDrawerModal.propTypes = {
  closeBtnPosition: PropTypes.string,
  closeBtnSize: PropTypes.number,
  closeBtnVertPosValue: PropTypes.string,
  closeBtnHorizonPosValue: PropTypes.string,
  closeBtnColor: PropTypes.string,
  borderRadius: PropTypes.string,
  hasTopPadding: PropTypes.bool,
  children: PropTypes.node,
  isVisible: PropTypes.bool,
  onCloseModal: PropTypes.func,
  backButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  title: PropTypes.string,
  lineHeight: PropTypes.string,
  fontSize: PropTypes.string,
  isBlocking: PropTypes.bool,
  width: PropTypes.number,
  closeButton: PropTypes.bool,
  innerPadding: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default StyledDrawerModal;
